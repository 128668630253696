/*----------------------------------------------------------------------------*/
/*	VENDOR
/*----------------------------------------------------------------------------*/
/*---------- Google Maps -----------------------------------------------------*/
.mapWrapper {
	border: 1px double #aaa;
}
.content-col-map .mapWrapper {
	border: none;
	background-color: rgba(255,255,255,0.1);
}
.googleMap {
	height: 300px;
}
.googleInfo {
	font: 400 15px/24px $font_body;
	color: #333;
	line-height: 23px;
	text-align: center;
	padding: 25px 5px 10px 5px;
	width: 200px;
}
.googleInfo span {
	display: block;
	font: 700 15px/24px $font_body;
	color: #222;
}
.googleInfo a {
	display: block;
	font: 400 15px/24px $font_body;
	text-transform: uppercase;
	text-decoration: none;
	color: #ecc;
	margin-top: 10px;
}
.googleMap img {
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	margin-bottom: 1%;
}
.address-block {
	height: 40px;
	text-align: center;
	background-color: #bdd;
	color: #fff;
	font: 18px/24px $font_body;
	padding: 8px 20px;
}
section.map-section {
	padding: 0;
}


/*---------- Colorbox --------------------------------------------------------*/
/*
	Colorbox Core Style:
	The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0 !important; width:100% !important;  z-index:9999; overflow:hidden;}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative; margin:0 auto; float:none !important;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/*
	User Style:
	Change the following styles to modify the appearance of Colorbox.  They are
	ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#000; opacity: 0.7 !important; filter: alpha(opacity = 70);}
#colorbox{outline:0;}
#cboxContent{background:#fff; overflow:hidden;border:10px solid #231f20;}
.cboxIframe{background:#fff;}
#cboxError{padding:50px; border:1px solid #ccc;}
#cboxTitle{position:absolute; bottom:4px; left:0; text-align:center; width:100%; color:#949494;}
#cboxCurrent{position:absolute; bottom:4px; left:58px; color:#949494;}
#cboxLoadingOverlay{background:url('img/cbox-loading_background.png') no-repeat center center;}
#cboxLoadingGraphic{background:url('img/cbox-loading.gif') no-repeat center center;}
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; background:none; }
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}
#cboxSlideshow{position:absolute; bottom:4px; right:30px; color:#0092ef;}
#cboxPrevious{position:absolute; top:50%; left:0; margin-top:-35px; padding:35px; background:url('img/cbox-left-arrow.png') no-repeat center; width:35px; height:35px; text-indent:-9999px;}
#cboxNext{position:absolute; top:50%; right:0px; margin-top:-35px; padding:35px; background:url('img/cbox-right-arrow.png') no-repeat center; width:35px; height:35px; text-indent:-9999px;}
#cboxClose{position:absolute; top:0; right:0; background:url('img/cbox-close.png') no-repeat center; width:44px; height:43px; text-indent:-9999px;}
.cboxIE #cboxTopLeft, .cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight, .cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter, .cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft, .cboxIE #cboxMiddleRight {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
}
#cboxOverlay, #cboxWrapper, #colorbox {
	position: absolute;
	top: 0;
	left: 0!important;
	width: 100%!important;
	z-index: 9999;
	overflow: hidden;
}
#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: .7!important;
	filter: alpha(opacity=70);
}
#cboxContent {
	overflow: hidden;
	border: 10px solid #231f20;
	position: relative;
	margin: 0 auto;
	float: none!important;
}
#cboxClose, #cboxNext, #cboxPrevious, #cboxSlideshow {
	cursor: pointer;
	border: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	background: 0 0;
}
#cboxClose {
	position: absolute;
	top: 0;
	right: 0;
	background: url('img/cbox-close.png') center no-repeat;
	width: 44px;
	height: 43px;
	text-indent: -9999px;
}
#cboxNext, #cboxPrevious {
	text-indent: -9999px;
	position: absolute;
	top: 50%;
	margin-top: -35px;
	padding: 35px;
	width: 35px;
	height: 35px;
}
#cboxPrevious {
	left: 0;
	background: url('img/cbox-left-arrow.png') center no-repeat;
}
#cboxNext {
	right: 0;
	background: url('img/cbox-right-arrow.png') center no-repeat;
}
#cboxLoadedContent iframe {
	width: 1px;
	min-width: 100%;
	min-height: 100%;
}



.slick-list {
	margin: 0 45px;
	overflow: visible;
}
.slick-prev, .slick-next {
	cursor: pointer;
	background-color: #aaa;
	height: 72px;
	width: 72px;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	text-indent: -9999px;
	border: none;
	z-index: 20;
	&.slick-disabled {
		opacity: 0.7;
		cursor: auto;
	}
	&::after {
		font: 300 24px/20px $font_body;
		color: #cee;
		position: absolute;
		top: 53%;
		text-indent: 0;
		-webkit-transform: translateY(-50%);
		   -moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
				transform: translateY(-50%);
	}
	@media all and ( max-width: 1200px ) {
		height: 6vw;
		width: 6vw;
		&::after {
			font: 300 2vw/1.66666vw $font_body;
		}
	}
	@media all and ( max-width: 900px ) {
		height: 54px;
		width: 54px;
		&:after {
			font: 300 18px/15px $font_body;
		}
	}
}
.slick-prev {
	left: 0;
	-webkit-transform: translateX(-50%) translateY(-50%);
	   -moz-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
	&::after {
		content: '–';
		right: 15px;
		@media all and ( max-width: 1200px ) {
			right: 1.25vw;
		}
	}
}
.slick-next {
	right: 0;
	-webkit-transform: translateX(+50%) translateY(-50%);
	   -moz-transform: translateX(+50%) translateY(-50%);
		-ms-transform: translateX(+50%) translateY(-50%);
			transform: translateX(+50%) translateY(-50%);
	&::after {
		content: '+';
		left: 15px;
		@media all and ( max-width: 1200px ) {
			left: 1.25vw;
		}
	}
}
.slick-dots {
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;
	text-align: center;

	li {
		display: inline-block;
	}
	button {
		font-size: 0;
		line-height: 0;
		display: block;
		width: 20px;
		height: 20px;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
		position: relative;

		&::before {
			content: '•';
			font-size: 20px;
			color: #fff;
			line-height: 22px;
		}

	}
	.slick-active button::before {
		color: #cee;
		font-size: 22px;
		text-shadow: 0 0 3px rgba(255,255,255,0.5);
	}
}
.slick-list.draggable {
	overflow: hidden;
}
